import { createContext, useContext, useEffect, useState } from "react";
import useGetPromotion from "../hooks/useGetPromotion";


const PromotionContext = createContext();

export const usePromotion = () => useContext(PromotionContext);

export const PromotionProvider = ({children}) => {

    const [promtion, setPromotion] = useState(null);

    const {data, refetch} = useGetPromotion();
    
    useEffect(() => {
        if (data && data.length>0) {
            setPromotion(data[0]);
        }
    }, [data])

    return (
        <PromotionContext.Provider value={promtion}>
            {children}
        </PromotionContext.Provider>
    )
}
