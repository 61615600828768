import { useEffect, useRef } from "react"
import { usePromotion } from "./context"
import { PromotionOverlayStyled } from "./style"
import { useProperty } from "../../../PropertyWithFilter/context";
import { useRouter } from "next/router";
import { identity } from "lodash";


export const PromotionOverlay = ({discount}) => {
    const router = useRouter();
    // const ref = useRef();
    const {promotion} = useProperty();
    if(!discount || discount <= 0 ) {
        return <></>;
    }
    return (
        <PromotionOverlayStyled>
            <img src={promotion?.imageString} style={{width: 48}} />
            {/* <span style={{
                fontSize: 10,
                color: "#fff"
            }}>{promotion.nameEn}</span> */}
            <span style={{
                width: 48,
                height: 21,
                textAlign:"center",
                backgroundColor:"#fff",
                border:"1px solid #EFEFEF",
                borderRadius: "5px",
                lineHeight: "21px", 
                color: "#00BEAD", 
                fontWeight:"bold",
                direction:"ltr"
                }}>-{discount}%</span>
        </PromotionOverlayStyled>
    )
}