import styled from "@emotion/styled";
import isPropValid from "@emotion/is-prop-valid";

const PromotionOverlayStyled = styled("div", {
  shouldForwardProp: (prop) => isPropValid(prop),
})(({ theme }) => ({
  position: "absolute",
  bottom: "0px",
  display: "flex",
  flexDirection: "column",
  gap: 8,
  background: "#00BEAD",
  borderRadius: "0px 15px 0px 0px",
  padding: "6px 4px",
  width: "64px",
  height: "90px",
  zIndex: 99,
}));



export { PromotionOverlayStyled };
